import "../Blog/Blog.scss"

const Blog = () => {

    //Placeholder for the blog content
    return (
        <div className="blog-container">
            <h1 className="blog-title__style">Coming Soon!!</h1>
        </div>
    )
}

export default Blog